<template>
  <v-container>
    <v-toolbar flat>
      <v-toolbar-title>{{ $t("modbus.master.title") }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn outlined color="primary" @click="openForm"
        >{{ $t("modbus.master.create") }}
      </v-btn>
    </v-toolbar>
    <p
      class="error text-left pa-3"
      v-if="errorMessage != undefined && errorMessage != ''"
    >
      {{ errorMessage }}
    </p>
    <v-data-table :items="masters" :headers="headers">
      <template v-slot:[`item.createdAt`]="{ item }">
        {{ humanDate(item.createdAt) }}
      </template>

      <template v-slot:[`item.updatedAt`]="{ item }">
        {{ humanDate(item.updatedAt) }}
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn @click="selectEdit(item)" icon>
          <v-icon color="primary">mdi-pen</v-icon>
        </v-btn>

        <v-btn @click="selectRemove(item)" icon>
          <v-icon color="red">mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <modbus-create-dialog
      v-model="formDialog"
      :master="selectedItem"
      @save="save"
    />

    <delete-dialog
      :dialog="confirmDelete"
      v-on:confirm-delete="remove"
      v-on:cancel-dialog="confirmDelete = false"
    />
  </v-container>
</template>

<script>
import ModbusRepository from "../../api/repositories/modbusRepository";
import ModbusCreateDialog from "./MasterCreateDialog.vue";
import DeleteDialog from "../common/DeleteDialog.vue";

export default {
  components: { DeleteDialog, ModbusCreateDialog },

  name: "ModbusMasterList",

  data() {
    return {
      masters: [],
      formDialog: false,
      confirmDelete: false,
      selectedItem: undefined,

      errorMessage: "",

      headers: [
        {
          text: this.$t("modbus.master.fields.modbusMasterId"),
          value: "modbusMasterId",
        },
        { text: this.$t("modbus.master.fields.name"), value: "name" },
        { text: this.$t("modbus.master.fields.host"), value: "host" },
        { text: this.$t("modbus.master.fields.port"), value: "port" },
        {
          text: this.$t("modbus.register.fields.createdAt"),
          value: "createdAt",
        },
        {
          text: this.$t("modbus.register.fields.updatedAt"),
          value: "updatedAt",
        },
        { text: "", value: "actions" },
      ],
    };
  },

  methods: {
    openForm() {
      this.selectedItem = undefined;
      this.formDialog = true;
    },

    async save(payload) {
      let errMsg;
      if (this.selectedItem == undefined) {
        errMsg = await ModbusRepository.createMaster(payload)
          .then(() => undefined)
          .catch((e) => e.response.data);
        this.errorMessage = errMsg ?? "";
      } else {
        errMsg = await ModbusRepository.updateMaster(
          this.selectedItem.modbusMasterId,
          payload
        )
          .then(() => undefined)
          .catch((e) => e.response.data);
        this.errorMessage = errMsg ?? "";
      }

      await this.fetch();
    },

    async remove() {
      const errMsg = await ModbusRepository.removeMaster(
        this.selectedItem.modbusMasterId
      )
        .then(() => undefined)
        .catch((d) => d.response.data);
      this.confirmDelete = false;
      this.errorMessage = errMsg ?? "";

      await this.fetch();
    },

    async fetch() {
      if (this.permitted("Modbus.View"))
        this.masters = await ModbusRepository.getMasters()
          .then((d) => d.data)
          .catch(() => []);
      else this.masters = [];
    },

    selectRemove(item) {
      this.selectedItem = item;
      this.confirmDelete = true;
    },

    selectEdit(item) {
      this.selectedItem = item;
      this.formDialog = true;
    },
  },

  async created() {
    await this.fetch();
  },
};
</script>
